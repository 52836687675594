var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"019d272711e6d4dbcd2bb5c01acb57a17fffe452"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    new Sentry.Replay({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  // beforeSend(event, hint) {
  //   // Check if it is an exception, and if so, show the report dialog
  //   if ((process.env.NODE_ENV === 'development') && event.exception && event.event_id) {
  //     Sentry.showReportDialog(
  //       {
  //         eventId: event.event_id,
  //         lang: "ko",
  //         title: "죄송합니다. 오류가 발생했습니다",
  //         subtitle: "개발팀에 문제가 전달 되었습니다. 좀 더 자세한 내용을 입력해주시면 더 빠른 해결이 가능합니다.",
  //         subtitle2: "",
  //         labelComments: "문제에 대한 자세한 설명",
  //         labelSubmit: "전송",
  //         showBranding: false,
  //         successMessage: "의견 주셔서 감사합니다. 빠른 시일 내에 확인하겠습니다.",
  //       }
  //     );
  //   }
  //   return event;
  // },
});
